import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Open the activity page`}</h4>
    <p>{`When you are in the list, click on an activity to open the page. On the map,
click on a track, then again on the info bar that opens up.`}</p>
    <Image src="features/mapandlist2.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Main information`}</h4>
    <p>{`At the top of the activitypage you see the navigation to go back to the screen
before or to close the window. Click on "Show on map" to see this activity on
the map.`}</p>
    <br />
    <p>{`Below you see the icon of the activity type - "MTB singletrack" in this case.
Next to it the name, location, upload date and user. Also important information
like length, total ascend and descend.`}</p>
    <br />
    <p>{`Below the blue `}<Link to="/features/activitybuttons" mdxType="Link">{`action buttons`}</Link>{` you
find the pictures. Swipe left or right to see more pictures.`}</p>
    <Image src="features/activitypagetop2.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Description`}</h4>
    <p>{`The text is shown in in its original language. Click on "translate" and select
your language for translation.`}</p>
    <Image src="features/activitypagedescription.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Local services`}</h4>
    <p>{`Nearby local services like sport shops, guide or shuttle companies,
accomodations, cafés or restaurants might be listed if available.
Click on one for more detailed information`}</p>
    <br />
    <p>{`If you have a local service and would like to be visible on Trailguide,
please contact us.`}</p>
    <Image src="features/activitypagelocalservices.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <br />
    <br />
    <h4>{`Map`}</h4>
    <p>{`The map shows the track of the activity. It is locked by default to prevent it
from moving when you scroll over.`}</p>
    <br />
    <p>{`Pinching the map with two fingers enables you to zoom or move it.`}</p>
    <Image src="features/activitypagemap.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <p>{`Below the map is the height profile of the trail. The labels to the left
indicates the maximum and minimum altitude, while the labels at the bottom
indicates the ascent and descent.`}</p>
    <br />
    <p>{`Move your finger back and forth on the height profile to see this location
and data on the track in the map.`}</p>
    <br />
    <br />
    <Link className="float-left" to="/features/filter" mdxType="Link">
  ← Filter
    </Link>
    <Link className="float-right" to="/features/activitytabs" mdxType="Link">
  Tabs →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      